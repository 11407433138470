import React from "react";
import './Input.css'
import {useState} from 'react';
import Button from 'react-bootstrap/Button';
import copy from 'copy-to-clipboard';


function Input() {
  const[link, setLink] = useState("");
  const[deepLink, setDeepLink] = useState("No Deep Link Yet")

  function getLink(){
    // alert(link)
    let videoId = ''
    if(link.length > 0){
    let re = /(https?:\/\/)?(((m|www)\.)?(youtube(-nocookie)?|youtube.googleapis)\.com.*(v\/|v=|vi=|vi\/|e\/|embed\/|user\/.*\/u\/\d+\/)|youtu\.be\/)([_0-9a-z-]+)/i;
     try {
      videoId =link.match(re)[8]; 
    } catch(e) {
      alert("There is an issue with your link. Make sure it is a proper Youtube link")
    }




      var newUrl = `https://kidan-link-server-6.onrender.com/vid/${videoId}`;
      setDeepLink(newUrl)
    } else {
      alert("There is an issue with your link. Make sure it is a proper Youtube link")
    }

  }
  
  function handleChange(e) {
    setLink(e.target.value)
  }

  function copy(){
    if(deepLink.length > 0){
      navigator.clipboard.writeText(deepLink)
      .then(() => {
        console.log('Text successfully copied');
        alert("Link Copied Succefully")
        // Optionally, you can show a message to users, informing them that the text was copied.
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
        // Handle the error (for example, by informing the user that the copy didn't work)
      });
    }else{
      alert("Improper Link")
    }


    
  }


  return (
    <div className="main">
        <div className="input">
            <input placeholder="Paste Youtube Link Here" onChange={handleChange}  />
            <Button variant="success" onClick={getLink}>GO</Button>
        </div>


        <div className="deepLink">
          <input placeholder="No generated link yet" value={deepLink} readOnly  />
          <Button variant="info" onClick={copy}>COPY</Button>
        </div>
    </div>

  );
}

export default Input;
