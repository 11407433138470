import React from 'react'
import Jumbo from '../Components/Jumbo';
import Input from '../Components/Input';
import './Main.css'
function Main() {
  return (
    <div>
        <Jumbo />
        <Input />
    </div>
  )
}

export default Main