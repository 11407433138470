import React from 'react'
import Image from 'react-bootstrap/Image';
import './Jumbo.css'

function Jumbo() {
  return (
    <>
      <div className='jumbo'>
        DIRECT2YT
      </div>

      <p>
        Direct 2 YT is a Youtube Deep Link generator.<br></br>
        Use the link generated by us to send your audience directly to the Youtube app
        
      </p>
    </>


  )
}

export default Jumbo